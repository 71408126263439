<script setup>
import { h } from 'vue'
import gsap from '~/vendors/gsap'
import SplitType from 'split-type'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import GlobalEmitter from '~~/glxp/utils/emitter'

const props = defineProps({
  json: {
    type: Object,
    required: true,
  },
  links: {
    type: Object,
    default: null,
  },
  size: {
    type: String,
    default: 'medium',
  },
  animate: {
    type: Boolean,
    default: false,
  },
  animateManual: {
    type: Boolean,
    default: false,
  },
  split: {
    type: Boolean,
    default: false,
  },
  sectionSlug: {
    type: String,
    default: null,
  },
})

const el = ref(null)

/**
 * Display
 */
const copySizeClass = computed(() => `text__copy-${props.size}`)

/**
 * Lines
 */
let stInstance
function splitLines() {
  if (stInstance) {
    stInstance.split()
    return
  }
  stInstance = new SplitType(el.value.$el.querySelectorAll('p'), {
    types: 'lines',
  })
}

onMounted(async () => {
  if (props.split) {
    await document.fonts.ready
    splitLines()
    GlobalEmitter.on('debouncedResize', splitLines)
  }
})

onBeforeUnmount(() => {
  GlobalEmitter.off('debouncedResize', splitLines)
})

/**
 * Element In View
 */
const isInView = ref(false)
function onEnter() {
  isInView.value = true
}

function onExit() {
  isInView.value = false
}

/**
 * Timeline
 */
let timeline
function initAnimation() {
  if (props.animate || props.animateManual) {
    splitLines()
    const { lines } = stInstance
    timeline = gsap.timeline({ paused: true }).from(lines, {
      opacity: 0,
      y: 15,
      duration: 1.5,
      stagger: 0.15,
      ease: 'power2.out',
      delay: 0.1, // this is hacky but it's improving the performance noticeably so who's to say
      lazy: false,
    })

    // resolves some issues with animation initialization
    timeline.progress(1).progress(0)

    if (isActive.value) {
      timeline.seek(timeline.duration())
    }
  }
}

function destroyAnimation() {
  timeline?.kill()
}

onMounted(() => {
  initAnimation()
  GlobalEmitter.on('debouncedResize', initAnimation)
})

onBeforeUnmount(() => {
  destroyAnimation()
  GlobalEmitter.off('debouncedResize', initAnimation)
})

/**
 * Parent element reveal
 */
const parentIsRevealed = ref(false)
function onReveal() {
  parentIsRevealed.value = true
}

function onUnreveal() {
  parentIsRevealed.value = false
}

onMounted(async () => {
  if (props.sectionSlug) {
    GlobalEmitter.on(`reveal:${props.sectionSlug}`, onReveal)
    GlobalEmitter.on(`unreveal:${props.sectionSlug}`, onUnreveal)
  }
})

onBeforeUnmount(() => {
  if (props.sectionSlug) {
    GlobalEmitter.off(`reveal:${props.sectionSlug}`, onReveal)
    GlobalEmitter.off(`unreveal:${props.sectionSlug}`, onUnreveal)
  }
})

/**
 * Animation triggers
 */

// const isActive = computed(() => {
//   if (props.animateManual) {
//     return parentIsRevealed.value && isInView.value
//   } else if (props.animate) {
//     return isInView.value
//   }
//   return true
// })

const isActive = ref(false)

watchEffect(() => {
  if (props.animateManual) {
    // active once parent is revealed and in view
    if (parentIsRevealed.value && isInView.value) {
      isActive.value = true
    }

    // inactive when parent is no longer revealed (element out of view does not matter)
    if (!parentIsRevealed.value) {
      isActive.value = false
    }
  } else if (props.animate) {
    if (isInView.value) {
      isActive.value = true
    } else {
      isActive.value = false
    }
  } else {
    isActive.value = true
  }
})

watch(isActive, (value) => {
  if (value) {
    timeline?.play(0)
  } else {
    timeline?.reverse(timeline.duration())
  }
})

/**
 * Custom node rendering
 */
const RichTextLink = resolveComponent('ContentfulRichTextLink')
const RichTextImage = resolveComponent('ContentfulRichTextImage')

function renderNodes() {
  const assetMap = new Map()
  props.links?.assets.block.forEach((asset) => {
    assetMap.set(asset.sys.id, asset)
  })

  const output = {
    [INLINES.ENTRY_HYPERLINK]: (node, key, next) => {
      const { target } = node.data
      return h(RichTextLink, { key, target }, () =>
        next(node.content, key, next)
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, key) => {
      const assetID = node.data.target.sys.id
      const asset = assetMap.get(node.data.target.sys.id)

      return h(RichTextImage, {
        key,
        url: asset ? asset.url : undefined,
        assetID: asset ? undefined : assetID,
      })
    },
  }
  ;[1, 2, 3, 4, 5].forEach((n) => {
    output[BLOCKS[`HEADING_${n}`]] = (node, key, next) =>
      h(
        `h${n}`,
        { key, class: `text__headline text__headline-${n}` },
        next(node.content, key, next)
      )
  })
  return output
}
</script>

<template>
  <InView
    @enter="onEnter"
    @exit="onExit"
    class="rich-text"
    :class="copySizeClass"
    ref="el"
  >
    <RichTextRenderer :document="json" :nodeRenderers="renderNodes()" />
  </InView>
</template>

<style lang="scss">
@import '~/styles/utils/functions';
@import '~/styles/utils/variables';
@import '~/styles/vendors/sass-mq/mq';

.rich-text {
  p:not(:last-of-type) {
    margin-bottom: 1em;
  }
  strong {
    color: $brand-white;
  }
  em {
    font-style: italic;
  }
  a {
    color: $brand-magenta;
    border-bottom: 1px solid $brand-magenta;
    transition: border 0.3s;
    &:hover,
    &:focus {
      border-bottom-color: rgba($brand-magenta, 0);
    }
  }

  ul,
  ol {
    margin: 2em 0;
  }
  li {
    padding-left: gs(1, 0, 'xxxs');

    @include mq($from: 'l') using ($from) {
      padding-left: gs(1, 0, 'l');
    }
    background: url('~/assets/images/icons/chevron-right--magenta.svg') -4px 3px
      no-repeat;
    background-size: 18px 18px;
    margin-bottom: 1em;
  }

  .text__headline {
    margin-bottom: 32px;
  }
}
</style>
