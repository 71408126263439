<script setup>
import SplitType from '~/utils/split-type'

const el = ref(null)
let instance
onMounted(async () => {
  await document.fonts.ready
  instance = new SplitType(el.value, { types: 'lines' })
})

onBeforeUnmount(() => {
  instance?.kill()
})
</script>

<template>
  <div ref="el" class="split-text-block">
    <slot />
  </div>
</template>
