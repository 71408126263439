import SplitType from 'split-type'

/**
 * Extends the core SplitType to adds some listeners to the constructor to
 * automatically re-split when the window resizes.
 *
 * Make sure you manually call .kill() when the parent component unmounts.
 */

const debounceMs = 250 // time in milliseconds to debounce window resize calls

class ExtendedSplitType extends SplitType {
  constructor(...params) {
    super(...params)
    if (window) {
      window.addEventListener('resize', this.onResize.bind(this))
    }
  }

  onResize() {
    if (this.isSplit) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setTimeout(() => {
        this.split()
      }, debounceMs)
    }
  }

  kill() {
    window.removeEventListener('resize', this.onResize.bind(this))
  }
}

export default ExtendedSplitType
