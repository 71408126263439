import { useContentful } from '~~/composables/useContentful'

export const useLinkedEntriesStore = defineStore({
  id: 'linkedEntriesStore',
  state: () => ({
    entries: [],
  }),

  actions: {
    async fetchLinkedEntry(entryID) {
      const contentful = useContentful()
      const response = await contentful.cda(entryID)

      const index = this.entries.indexOf(
        (entry) => entry.sys.id === response.sys.id
      )
      if (index !== -1) {
        this.entries[index] = response
      } else {
        this.entries.push(response)
      }
      return response
    },
  },

  getters: {
    getLinkedEntry(state) {
      return (id) => state.entries.find((entry) => entry.sys.id === id)
    },
  },
})
