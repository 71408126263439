<script setup>
/**
 * Since fetching the detail of linked entries in Contentful's rich text fields
 * causes issues with complexity limits, we're just doing an additional call to
 * fetch the entry's slug. It's not ideal, no, but nothing about Contentful is.
 */

import { useLinkedEntriesStore } from '~/store/linkedEntries'

const props = defineProps({
  target: {
    type: Object,
    required: true,
  },
})

const entryID = computed(() => props.target.sys.id)
const store = useLinkedEntriesStore()
const entry = computed(() => store.getLinkedEntry(entryID.value))
const url = computed(() => (entry.value ? entry.value.fields.slug : '#'))

function getOrFetchEntry() {
  if (!entry.value) {
    store.fetchLinkedEntry(entryID.value)
  }
}

// Lifecycle
onMounted(() => {
  getOrFetchEntry()
})
</script>

<template>
  <NuxtLink class="rich-text-link" :to="url"><slot /></NuxtLink>
</template>

<style lang="scss"></style>
